<template>
  <b10-base>
    <v-text-field
      v-model="fecha"
      disabled
      label="Fecha"
    />
    <v-text-field
      v-model="hora"
      disabled
      label="Hora"
    />
    <b10-autocomplete
      v-model="form.idmotivo_salida"
      :items="motivosSalida"
      item-value="idmotivo_salida_llavero"
      item-text="descripcion"
      label="Motivo de salida"
      clearable
      :rules="formRules.idmotivo_salida"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './LlaveroSalidaFormData'
import { currentDateTime } from '@/utils/date'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        fecha: currentDateTime(),
        idmotivo_salida: null,
      },
      formRules: {
        idmotivo_salida: [
          v => !!v || 'Campo requerido'
        ]
      },
      motivosSalida: [],
      hora: null,
      fecha: null
    }
  },
  async created () {
    this.motivosSalida = await Data.selectMotivoSalidaLlavero(this)
    if (this.motivosSalida.length === 1) {
      this.$set(this.form, 'idmotivo_salida', this.motivosSalida[0].idmotivo_salida_llavero)
    }
    this.fecha = this.$options.filters.shortDate(this.form.fecha)
    this.hora = this.$options.filters.shortTime(this.form.fecha)
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
  },
}
</script>
